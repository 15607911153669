import { Controller } from "@hotwired/stimulus"

const HIDDEN_CLASS = "-hidden"
const SELECTED_CLASS = "-selected"

export default class ReplacementVehiculeGroup extends Controller {
  static targets = ["empty", "replacementCar", "input", "modal", "car"]

  search() {
    this.modalTarget.classList.remove(HIDDEN_CLASS)
    const input = this.inputTarget.value.toLowerCase()
    const resultCount = this.carTargets.filter((car) => {
      const including = car.dataset.replacementVehiculeGroupCarNameData.toLowerCase().includes(input)
      if (including) car.classList.remove(HIDDEN_CLASS)
      else car.classList.add(HIDDEN_CLASS)
      return including
    }).length

    if (resultCount === 0) this.emptyTarget.classList.remove(HIDDEN_CLASS)
    else this.emptyTarget.classList.add(HIDDEN_CLASS)
  }

  blur(event) {
    if (!this.modalTarget.contains(event.relatedTarget) && !this.inputTarget.contains(event.target) && !event.target.classList.contains("form")) {
      this.close()
    }
  }

  selectCar(event) {
    const carElement = event.target.closest("[data-replacement-vehicule-group-target=\"car\"]")
    const checkBox = this.replacementCarTargets.find((car) => car.dataset.replacementVehiculeGroupCarIdData === carElement.dataset.replacementVehiculeGroupCarIdData)
    checkBox.checked = true
    checkBox.nextElementSibling.classList.remove(HIDDEN_CLASS)
    carElement.classList.add(SELECTED_CLASS)

    this.close()

    event.preventDefault()
    event.stopPropagation()
  }

  deleteCar(event) {
    const carPk = event.currentTarget.dataset.replacementVehiculeGroupCarIdData
    const replacementCar = this.replacementCarTargets.find((car) => car.dataset.replacementVehiculeGroupCarIdData === carPk)
    replacementCar.checked = false
    replacementCar.nextElementSibling.classList.add(HIDDEN_CLASS)
    this.carTargets.find((car) => car.dataset.replacementVehiculeGroupCarIdData === carPk).classList.remove(SELECTED_CLASS)
  }

  close() {
    this.modalTarget.classList.add(HIDDEN_CLASS)
    this.emptyTarget.classList.add(HIDDEN_CLASS)
    this.carTargets.forEach((car) => car.classList.remove(HIDDEN_CLASS))
    this.inputTarget.value = ""
  }
}
