import { Controller } from "@hotwired/stimulus"

const HIDDEN_CLASS = "-hidden"
const FOCUS_CLASS = "-focus"
const LEFT_CLASS = "-left"
const RIGHT_CLASS = "-right"

export default class ReplacementChoice extends Controller {
  static targets = ["modal", "emptySection"]

  toogleChoicePannel(event) {
    const dom = event.currentTarget
    const isOpenned = dom.classList.contains(FOCUS_CLASS)
    this.reset()

    if (isOpenned) return

    this.openModal(dom)
  }

  reset() {
    this.modalTargets.forEach((modal) => modal.classList.add(HIDDEN_CLASS))
    this.emptySectionTargets.forEach((section) => {
      section.classList.remove(FOCUS_CLASS)
      section.children[0].innerHTML = "Disponible"
    })
  }

  // Internal

  openModal(dom) {
    dom.classList.add(FOCUS_CLASS)
    dom.children[0].innerHTML = "Fermer"
    dom.parentElement.nextElementSibling.classList.remove(HIDDEN_CLASS)
    if (this.isFirstSection()) dom.parentElement.previousElementSibling.classList.add(LEFT_CLASS)
    if (this.isLastSection()) dom.parentElement.nextElementSibling.classList.add(RIGHT_CLASS)
  }

  isFirstSection() {
    return this.sectionIndexValue === 0
  }

  isLastSection() {
    return this.sectionIndexValue === this.sectionLengthValue - 1
  }
}
