import { Controller } from "@hotwired/stimulus"

export default class ReplacementDate extends Controller {
  static targets = ["startDate", "endDate"]

  updateEndDate() {
    if (!this.startDateTarget.value) return

    const startDate = new Date(this.startDateTarget.value)
    this.endDateTarget.min = this.startDateTarget.value

    if (!this.endDateTarget.value) return

    const endDate = new Date(this.endDateTarget.value)
    if (startDate > endDate) {
      this.endDateTarget.value = this.startDateTarget.value
    }
  }

  updateStartDate() {
    if (!this.endDateTarget.value) return

    const endDate = new Date(this.endDateTarget.value)
    this.startDateTarget.max = this.endDateTarget.value

    if (!this.startDateTarget.value) return

    const startDate = new Date(this.startDateTarget.value)
    if (startDate > endDate) {
      this.startDateTarget.value = this.endDateTarget.value
    }
  }
}
